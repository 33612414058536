body{
	font-family: "Lato", sans-serif;
}
.elevation-z0 {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z1 {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z2 {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z3 {
	box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z4 {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z5 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z6 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.elevation-z7 {
	box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.elevation-z8 {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.elevation-z9 {
	box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.elevation-z10 {
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.elevation-z11 {
	box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.elevation-z12 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.elevation-z13 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.elevation-z14 {
	box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.elevation-z15 {
	box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.elevation-z16 {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.elevation-z17 {
	box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.elevation-z18 {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.elevation-z19 {
	box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.elevation-z20 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.elevation-z21 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.elevation-z22 {
	box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.elevation-z23 {
	box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.elevation-z24 {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.elevation-transition {
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	will-change: box-shadow;
}
.card{
	border: 0;
}
.sticky-nav{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px 0;
	z-index: 2;
	transition: 0.25s all;
}
.sticky-nav .container-fluid{
	position: relative;
	z-index: 1;
}
.sticky-nav .navbar{
	padding: 0;
}
.sticky-nav .navbar-toggler{
	border: 0;
	margin-left: 30px;
	padding: 0;
}
.sticky-nav .navbar-toggler-icon{
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	width: 45px;
	height: 45px;	
}
.sticky-bg{
	background-color: white;
	background-image: url(/static/media/logo-icon.c2a20f81.svg);
	background-size: 60px;
	background-position: 30px center;
	background-repeat: no-repeat;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	padding: 20px 0;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition: 0.25s all;
}
.sticky-nav.show{
	padding: 20px 0;
	z-index: 999;
}
.sticky-nav.show .sticky-bg{
	-webkit-transform: translateY(0%);
	        transform: translateY(0%);
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.sticky-nav.show .navbar-toggler-icon{
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(45,80,163, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.sticky-nav .btn{
	margin-right: 30px;
	background-color: #2d51a3;
	border-radius: 4px;
	color: white;
	font-size: 18px;
	font-weight: bold;
	padding: .5rem 1.5rem;
	line-height: 24px;
}
.btn .icon{
	display: block;
	height: 24px;
	width: 24px;
	background-image: url(/static/media/cart.39603e2c.svg);
	margin-right: 10px;
	float: left;
}
.sticky-nav .btn:hover{
	background-color: #254482;
}
.navbar-collapse{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #2d51a3;
	z-index: 999;
	padding: 30px;
}
.close{
	float: left;
	opacity: 1 !important;
}
.close .navbar-toggler-icon{
	width: 45px;
	height: 45px;
	background-image: url(/static/media/close.669e4580.svg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
#navbarNav .navbar-nav{
	clear: both;
	float: left;
	margin-top: 30px;
	margin-left: 4px;
}
#navbarNav .nav-link{
	font-size: 24px;
	color: white;
}
.hero{
	background-image: url(/static/media/city-lawn-bg.bb6bbb41.jpg);
	background-position: center center;
	background-size: cover;
	border-bottom: 12px solid #2d51a3;
	min-height: 550px;
	position: relative;
	margin: 0;
}
.hero::before{
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.4);
}
.hero .logo{
	z-index: 1;
	position: relative;
}
.search{
	margin-top: 35px;
	margin-bottom: 70px;
	border: 4px;
	position: relative;
}
.search .input-container{
	position: relative;
	z-index: 100;
}
.search input{
	border: 0;
	height: 
}
.search input[type="text"]{
	border-radius: 4px 0px 0px 4px;
	padding: 0 30px;
}
.search input[type="submit"]{
	background-color: #2d51a3;
	background-image: url(/static/media/search.bf3f5ad6.svg);
	background-position: center center;
	background-repeat: no-repeat;
	height: 70px;
	width: 60px;
	border-radius: 0 4px 4px 0;
}
.search input[type="submit"]:hover{
	background-color: #254482;
}
.autocomplete{
	position: absolute;
	top: 100%;
	background-color: white;
	z-index: 99;
	padding-top: 4px;
	margin-top: -4px;
	border-radius: 0 0 4px 4px;
	width: 100%;
}
.autocomplete span{
	display: block;
	float: left;
	width: 100%;
	padding: 8px 30px;
	font-size: 14px;
	text-align: left;
}
.autocomplete span:hover{
	background-color: rgba(0,0,0,0.12);
}
#services, #services .row{
	position: relative;
}
#services::after{
	content: "";
	position: absolute;
	width: 100%;
	background-color: #2d51a3;
	height: 0%;
	top: 0;
	left: 0;
	transition: 0.5s height;
}
#services.active::after{
	height: 50%;
}
.selected-address{
	font-size: 18px;
	color: white;
	z-index: 1;
	margin: 15px 0;
}
.service{
	margin: 30px 0;
	position: relative;
	z-index: 2;
	transition: 0.5s all;
}
.active .service{
	margin-top: 30px;
}
.service .card{
	padding: 20px;
	transition: 0.5s all;
}
.service .card img{
	margin-bottom: 10px;
	margin-top: 20px;
}
.service .price{
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 10px;
}
.service .price.discount{
	color: #4caf50;
}
.service .price.small{
	font-size: 20px;
	margin-top: 7px;
	margin-bottom: 26px;
}
.service .btn{
	background-color: #2d51a3;
	border-radius: 4px;
	color: white;
	font-size: 16px;
	font-weight: bold;
	height: 52px;
	width: 140px;
	cursor: pointer;
}
.service .disclaimer{
	font-size: 10px;
	margin-top: 30px;
}
.service .disclaimer p:last-of-type{
	margin-bottom: 0;
}
.service .btn.disabled{
	background-color: #f2f2f2;
	color: #e6e6e6;
}
.service .btn:not(.disabled):hover{
	background-color: #254482;
}
.service .btn.selected, .service .btn.selected:hover{
	background-color: #4caf50;
	background-image: url(/static/media/check.4a5e5ac9.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 36px 36px;
}
#beforeAfter{
	background-color: #2d51a3;
	border: 0;
	border-radius: 4px;
	color: white;
	font-size: 16px;
	font-weight: bold;
	height: 52px;
	cursor: pointer;
	padding-left: 48px;
	background-image: url(/static/media/album.11f15886.svg);
	background-size: 24px;
	background-repeat: no-repeat;
	background-position: center left 14px;
	margin-bottom: 20px;
}
.about{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: rgba(45,80,163,0.1);
}
.about .card{
	padding: 50px;
	margin-bottom: 30px;
}
.map{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: rgba(45,80,163,0.2);
}
.testimonials{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: rgba(45,80,163,0.3);
}
.testimonials .card{
	padding: 84px 20px;
	margin-bottom: 30px;
}
.testimonials .card::before{
	content: "";
	display: block;
	position: absolute;
	height: 48px;
	width: 48px;
	top: 20px;
	left: 20px;
	background-image: url(/static/media/quote-open.cd41f140.svg);
}
.testimonials .card::after{
	content: "";
	display: block;
	position: absolute;
	height: 48px;
	width: 48px;
	bottom: 20px;
	right: 20px;
	background-image: url(/static/media/quote-close.2753b511.svg);
}
.testimonials .card p{
	margin: 0;
}
.testimonials .card span.person{
	position: absolute;
	bottom: 20px;
	right: 88px;
	font-size: 18px;
	font-weight: bold;
	display: block;
	height: 48px;
	line-height: 48px;
}
.footer-top{
	background-color: #e6e6e6;
	padding-top: 15px;
	padding-bottom: 15px;
}
.footer-bottom{
	background-color: #E6E6E6;
	padding-top: 10px;
	padding-bottom: 10px;
}
.slide-out{
	position: fixed;
    width: 100%;
    max-width: 380px;
    top: 0;
    right: 0;
    background: white;
    height: 100%;
    z-index: 9999;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: 0.25s all;
    overflow: scroll;
}
.slide-out.open{
	-webkit-transform: translateX(0%);
	        transform: translateX(0%);
}
.slide-out .cart{
    padding: 30px;
    padding-bottom: 0;
}
.slide-out .continue{
	background-color: #2d51a3;
	display: block;
	padding: 30px 20px;
	cursor: pointer;
	font-size: 16px;
	color: white;
	font-weight: bold;
}
.slide-out .continue:hover{
	background-color: #254482;
}
.slide-out #slideCheckout{
	background-color: #2d51a3;
	color: white;
	margin-right: 30px;
	margin-top: 20px;
	float: right;
}
.slide-out #slideCheckout:hover{
	background-color: #254482;
}
.cart-item{
	line-height: 36px;
	padding: 22px 0;
	border-bottom: 1px solid #cccccc;
}
img.cart-icon{
	display: block;
	width: 36px;
	height: 36px;
	float: left;
}
.cart-name, .cart-price{
	font-size: 18px;
	font-weight: bold;
}
.cart-price{
	float: right;
}
.cart .total table{
	float: right;
	margin-top: 20px;
	margin-bottom: 20px;
}
.cart .total tr td{
	padding-top: 5px;
	font-weight: bold;
	font-size: 18px;
	text-align: right;
}
.cart .total tr td:first-of-type{
	padding-right: 40px;
}
.cart .total .discount{
	color: #4caf50;
}
.btn.remove{
	margin-top: 6px;
	height: 24px;
	width: 24px;
	background-image: url(/static/media/remove.1433d48a.svg);
	float: right;
}
.btn.remove:hover{
	background-color: rgba(0,0,0,0.12);
}
.modal-header, .modal-footer{
	border: 0;
}
.modal-body form{
	margin-bottom: 0;
}
.modal-footer {
	padding-top: 0;
}
.modal-footer .btn{
	background-color: #2d51a3;
	border-radius: 4px;
	color: white;
	font-size: 18px;
	font-weight: bold;
	padding: .5rem 1.5rem;
	line-height: 24px;
}
.modal-footer .btn.white{
	background-color: #FFF;
	color: #000;
}
.modal-footer .btn:hover{
	background-color: #254482;
}
.modal-footer .btn.white:hover{
	background-color: rgba(0,0,0,0.12);
	color: #000;
}
.form-group label{
	margin-bottom: 5px;
	font-size: 12px;
	font-weight: bold;
}
.modal-content .react-datepicker{
	font-family: "Lato";
}
.modal-content .react-datepicker, .react-datepicker__month-container{
	width: 100%;
}
.modal-content .react-datepicker__header{
	padding: 12px;
}
.modal-content .react-datepicker__navigation{
	top: 16px;
}
.modal-content .react-datepicker__navigation--previous{
	right: 45px;
	left: auto;
	border-right-color: #2d51a3;
}
.modal-content .react-datepicker__navigation--next{
	border-left-color: #2d51a3;
}
.modal-content .react-datepicker__current-month{
	text-align: left;
}
.modal-content .react-datepicker__month{
	padding: 12px;
}
.modal-content .react-datepicker__day-name, .modal-content .react-datepicker__day, .modal-content .react-datepicker__time-name{
	margin: 0;
	width: 14.2%;
}
.modal-content .react-datepicker__day--outside-month{
	color: #BCBCBC;
}
.modal-content .react-datepicker__day:hover{
	background-color: rgba(0,0,0,0.12);
}
.modal-content .react-datepicker__day--selected, .modal-content .react-datepicker__day--in-selecting-range, .modal-content .react-datepicker__day--in-range{
	background-color: #2d51a3;
}
.modal-content .react-datepicker__day--selected:hover, .modal-content .react-datepicker__day--in-selecting-range:hover, .modal-content .react-datepicker__day--in-range:hover{
	background-color: #254482;
}
.alert{
	margin-top: 20px;
}
.alert p{
	margin-bottom: 0;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.sticky-nav{
		z-index: 1;
	}
	.service .card{
		padding: 40px;
		padding-top: 0;
	}
	.active .service .card{
		padding: 40px;
	}
	.service .card img{
		margin-bottom: 30px;
	}
	.service .price{
		margin-bottom: 20px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.hero{
		background-attachment: fixed;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
